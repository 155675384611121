import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { useCanUserPerformActions } from 'client-lib';
import { useApolloClient } from '@apollo/client';
import {
  setUserAccountPolicies,
  setUserAccountPoliciesQueried,
} from '../../actions/session';
import includesAll from '../../utils/helpers/includesAll.ts';

/**
 * Returns an array of policiesToCheck, indicating if the user has the policy or not.
 * @param {AVAILABLE_PERMISSIONS[]} currentPolicies array of active policies for the user
 * @param {AVAILABLE_PERMISSIONS[]} policiesToCheck array of policies to check user has
 * @returns {[policy_key]: boolean}
 */
const hasRequestedPermission = (currentPolicies, policiesToCheck) => {
  const policies = policiesToCheck.reduce((acc, policy) => {
    acc[policy] = currentPolicies.includes(policy);
    return acc;
  }, {});
  return policies;
};

/**
 * Load the desired account policies into Redux for the current user.
 * Does not re-lookup the same set of permissions if they have already been queried,
 * making this a safe hook to call multiple times.
 * Once loaded, permissions are appended to the existing set and emited to the store.
 *
 * Intedned to be called any place the checkIfCurrentUserHasPermission(..) function is
 * called within the web repo.
 * NOT Inteded for where the checkIfCurrentUserHasPermission(..) is called inside client-lib!
 * @param {actionList: AVAILABLE_PERMISSIONS[], userId: string} param0 list of actions and userId
 * @returns void
 */
const useGetUserAccountPolicies = ({ actionList, userId }) => {
  const dispatch = useDispatch();
  const client = useApolloClient();

  const queriedPolicies = useSelector(
    (state) => state.session.currentUser?.accountPoliciesQueried || []
  );

  const loadedPolicies = useSelector(
    (state) => state.session.currentUser?.accountPolicies?.[0]?.actions || []
  );

  const ff_beta_authorization = useSelector(
    (state) => state.accountData.account.ff_beta_authorization
  );

  const [loadingAccountPolicies, setLoadingAccountPolicies] = useState(false);

  // Need to update useCanUserPerformAction to return a lazyload
  // Needed so the hook can be called, but optionally execute
  //  the graph query after userId is found.
  const [canUserPerformActions, { loading, error, data }] =
    useCanUserPerformActions({
      userId,
      actions: actionList,
      client,
    });

  useEffect(() => {
    // Possible optimization is to remove all actions that have already been queried
    // Ensuring the backend only gets the actions it needs to check, for
    // a set that has SOME actions that are not already queried.
    // Not sure if that helps the backend sufficently to be worth the effort.
    const allActionsQueried = includesAll(queriedPolicies, actionList);
    if (
      !ff_beta_authorization ||
      loadingAccountPolicies ||
      !userId ||
      allActionsQueried
    )
      return;
    setLoadingAccountPolicies(true);
    canUserPerformActions();
  }, [queriedPolicies, userId, loadingAccountPolicies, ff_beta_authorization]);

  useEffect(() => {
    if (error) {
      console.error('useGetUserAccountPolicies: error: ', error);
      return;
    }
    if (loading || !data) return;

    // Redux stores current account policies in an array with an
    // accountId as part of that single array element.
    // [{ accountId: 1, actions: ['action1', 'action2'] }]
    // API returns single account/actions.  Redux is using an array,
    // likely to allow for easy mashing with groups.  Mutate to match single
    // array element for redux before dispatch.
    const accountPolicy = data.canUserPerformActions;
    // Destructuring to remove 'error' field that comes back
    const policies = [
      {
        accountId: accountPolicy.accountId,
        actions: accountPolicy.actions,
      },
    ];
    dispatch(setUserAccountPolicies(policies));
    dispatch(setUserAccountPoliciesQueried(actionList));

    setLoadingAccountPolicies(false);
  }, [loading, error, data]);

  const requestedPolicies = useMemo(
    () => hasRequestedPermission(loadedPolicies, actionList),
    [loadedPolicies, actionList]
  );

  return requestedPolicies;
};

export default useGetUserAccountPolicies;
