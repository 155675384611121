import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import HorizontalBarWidget from './HorizontalBarWidget';
import {
  FIVE_MIN,
  ONE_HOUR,
  FOUR_HOURS,
  EIGHT_HOURS,
} from '../../utils/helpers/constants';
import { getColorTimeLabelObj, getColorCodes } from './colorTimeLabelsData';

const sortedGroupThreadsByTime = (threadData) => {
  const currentTime = new Date(new Date().toISOString());
  const colorTimeLabelObj = getColorTimeLabelObj(i18n);

  const barData = [
    { time: colorTimeLabelObj[0].label, count: 0 },
    { time: colorTimeLabelObj[1].label, count: 0 },
    { time: colorTimeLabelObj[2].label, count: 0 },
    { time: colorTimeLabelObj[3].label, count: 0 },
    { time: colorTimeLabelObj[4].label, count: 0 },
  ];

  threadData?.forEach((data) => {
    const timeStamp = new Date(data.insertedAt);
    const differenceFromNow = currentTime.getTime() - timeStamp.getTime();

    switch (true) {
      case differenceFromNow < FIVE_MIN:
        barData[0].count += 1;
        break;
      case differenceFromNow < ONE_HOUR:
        barData[1].count += 1;
        break;
      case differenceFromNow < FOUR_HOURS:
        barData[2].count += 1;
        break;
      case differenceFromNow >= FOUR_HOURS && differenceFromNow < EIGHT_HOURS:
        barData[3].count += 1;
        break;
      default:
        barData[4].count += 1;
        break;
    }
  });
  return barData;
};

const UnclaimedSingleGroupWidget = ({ unclaimedThreads, unclaimedTotal }) => {
  return (
    <HorizontalBarWidget
      barColors={getColorCodes(i18n)}
      graphData={sortedGroupThreadsByTime(unclaimedThreads)}
      graphDataName="time"
      title={i18n.t('threads-Dashboard-unclaimedConversations', {
        defaultValue: 'Unclaimed Conversations',
      })}
      total={unclaimedTotal}
      countKey="count"
      breakpointWidth="100%"
    />
  );
};

UnclaimedSingleGroupWidget.propTypes = {
  unclaimedThreads: PropTypes.array.isRequired,
  unclaimedTotal: PropTypes.number.isRequired,
};

export default UnclaimedSingleGroupWidget;
