import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import i18n from 'i18n-js';
import { contactName } from 'client-lib/src/lib/utils/helpers';
import { Avatar, Heading4, IconButton, Text } from '../../elements';
import ChipLabel from '../../elements/Chip/ChipLabel';
import LABEL_THEMES from '../../styles/themes/library/label';
import FormattedDateTime from '../Common/FormattedDateTime';
import { getLightText } from './styles';

const FlexData = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  overflow: hidden;
`;

const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContactTD = styled.td`
  padding: 16px 0 16px 16px;
`;

const FeedbackTD = styled.td`
  padding: 16px 16px 16px 0;
`;

const OverflowWrapper = styled.div`
  text-wrap: nowrap;
  display: block;
  width: 100%;
  overflow: hidden;
  p {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const getTimestampText = () => `
  font-weight: 400;
  font-size: 12px;
`;

const CONTACT_LABEL_COLORS = {
  DISSATISFIED: LABEL_THEMES.DARK_RED_LABEL,
  SATISFIED: LABEL_THEMES.DARK_GREEN_LABEL,
  NEUTRAL: LABEL_THEMES.DARK_GRAY_LABEL,
};

const RATING_TYPES = {
  DISSATISFIED: 'DISSATISFIED',
  SATISFIED: 'SATISFIED',
  NEUTRAL: 'NEUTRAL',
};

const getRatingChipLabel = (rating) => {
  switch (rating) {
    case RATING_TYPES.SATISFIED:
      return i18n.t('feedback-feedback-satisfied', {
        defaultValue: 'Satisfied',
      });
    case RATING_TYPES.DISSATISFIED:
      return i18n.t('feedback-feedback-dissatisfied', {
        defaultValue: 'Dissatisfied',
      });
    case RATING_TYPES.NEUTRAL:
      return i18n.t('feedback-feedback-neutral', {
        defaultValue: 'Neutral',
      });
    default:
      return '';
  }
};

const FeedbackTableRow = ({ row }) => {
  const { contact, submittedAt, rating, message } = row;
  const name = contactName(contact);

  return (
    <tr>
      <ContactTD>
        <FlexData>
          <Avatar size="lg" dataTestId="feedback-avatar">
            {name}
          </Avatar>
          <FlexData>
            <Heading4>{name}</Heading4>
            <Text customStyle={getTimestampText}>
              <FormattedDateTime value={submittedAt} />
            </Text>
          </FlexData>
        </FlexData>
      </ContactTD>
      <FeedbackTD>
        <FlexBetween>
          <FlexData>
            {rating && (
              <ChipLabel
                dataTestId="feedback-chip-label"
                backgroundColor={CONTACT_LABEL_COLORS[rating]}
                textColor={LABEL_THEMES.LABEL_TEXT}
                size="sm"
                noOutline
              >
                {getRatingChipLabel(rating)}
              </ChipLabel>
            )}
            <OverflowWrapper>
              <Text customStyle={getLightText}>{message}</Text>
            </OverflowWrapper>
          </FlexData>
          <IconButton noOutline dataTestId="row">
            <i className="ri-arrow-right-s-line" />
          </IconButton>
        </FlexBetween>
      </FeedbackTD>
    </tr>
  );
};

FeedbackTableRow.propTypes = {
  row: PropTypes.shape({
    contact: PropTypes.object.isRequired,
    submittedAt: PropTypes.string.isRequired,
    rating: PropTypes.string,
    message: PropTypes.string,
  }).isRequired,
};

export default FeedbackTableRow;
