import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { TableBody } from '@mui/material';
import i18n from 'i18n-js';
import { SORT_DIRECTION, usePaginated, FEEDBACK_QUERY } from 'client-lib';
import { useApolloClient } from '@apollo/client';
import { Card, Loading, SortBy, Table, TableHead } from '../../elements';
import FeedbackTableRow from './FeedbackTableRow';
import PaginationButtonsWrapper from '../Common/PaginationButtonsWrapper';

const getTableWrapper = () => `
  margin-top: 16px;
  max-height: calc(100vh - 272px);
  overflow: hidden;

  thead, tbody, tr, th, td {
    box-sizing: border-box;
  }
`;

const SortByWrapper = styled.div`
  margin-bottom: 16px;
`;

const TableBodyWrapper = styled.div`
  height: calc(100vh - 368px);
  overflow: auto;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const getPaginationStyles = () => `
  border-top: none;
  & > div:first-child {
    padding: 14px;
  }
`;

const PAGE_ROW_LIMIT = 20;

const FeedbackTable = ({ activeGroupIds, isRefetching, setIsRefetching }) => {
  const client = useApolloClient();
  const [page, setPage] = useState(1);
  const [sortOpen, setSortOpen] = useState(false);
  const [sortDirection, setSortDirection] = useState(SORT_DIRECTION.ascending);

  const SORT_OPTIONS = [
    {
      label: i18n.t('customers-ActivitiesHeader-newestFirst', {
        defaultValue: 'Newest First',
      }),
      value: SORT_DIRECTION.ascending,
    },
    {
      label: i18n.t('customers-ActivitiesHeader-oldestFirst', {
        defaultValue: 'Oldest First',
      }),
      value: SORT_DIRECTION.descending,
    },
  ];
  const sortSelection = SORT_OPTIONS.find(
    (option) => option.value === sortDirection
  );

  const {
    feedbackResponses,
    handleFetchMore,
    handlePrevious,
    pageInfo,
    loading,
    refetch,
  } = usePaginated({
    client,
    query: FEEDBACK_QUERY,
    key: 'feedbackResponses',
    queryVariables: {
      sortDirection,
      activeGroupIds,
    },
    resultsNumber: PAGE_ROW_LIMIT,
  });

  const totalCount = pageInfo?.totalCount || 0;

  // TODO: REMOVE ONCE WE USE REAL DATA
  const stubbedPageInfo =
    page === 1
      ? { hasPreviousPage: false, hasNextPage: true }
      : { hasPreviousPage: true, hasNextPage: false };

  const handleSort = (selection) => {
    setSortDirection(selection.value);
  };

  const handleNextPage = async () => {
    const newPage = page + 1;
    setPage(newPage);
    handleFetchMore();
  };

  const handlePrevPage = async () => {
    const newPage = page - 1;
    setPage(newPage);
    handlePrevious();
  };

  useEffect(() => {
    if (isRefetching && refetch) {
      refetch();
      setIsRefetching(false);
    }
  }, [isRefetching, refetch]);

  return (
    <>
      <Card customStyle={getTableWrapper}>
        <SortByWrapper>
          <SortBy
            options={SORT_OPTIONS}
            selection={sortSelection}
            onSelect={(val) => handleSort(val)}
            isOpen={sortOpen}
            setIsOpen={setSortOpen}
            dataTestId="feedback-sort"
            isFirstOption={sortSelection?.value === 'ASC'}
          />
        </SortByWrapper>
        <Table>
          <TableHead>
            <tr>
              <th>
                {i18n.t('settings-FinalMessage-customer', {
                  defaultValue: 'Contact',
                })}
              </th>
              <th>
                {i18n.t('settings-Support-feedback', {
                  defaultValue: 'Feedback',
                })}
              </th>
            </tr>
          </TableHead>
        </Table>
        {loading ? (
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
        ) : (
          <TableBodyWrapper>
            <Table>
              <TableBody>
                {feedbackResponses &&
                  feedbackResponses.map((row) => (
                    <FeedbackTableRow key={row.id} row={row} />
                  ))}
              </TableBody>
            </Table>
          </TableBodyWrapper>
        )}
      </Card>
      <Card customStyle={getPaginationStyles}>
        <PaginationButtonsWrapper
          handlePrevious={handlePrevPage}
          handleNext={handleNextPage}
          pageInfo={stubbedPageInfo}
          showPageCount
          page={page}
          totalPages={Math.ceil(totalCount / PAGE_ROW_LIMIT)}
        />
      </Card>
    </>
  );
};

FeedbackTable.propTypes = {
  activeGroupIds: PropTypes.array.isRequired,
  isRefetching: PropTypes.bool.isRequired,
  setIsRefetching: PropTypes.func.isRequired,
};

export default FeedbackTable;
