import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { AVAILABLE_PERMISSIONS } from 'client-lib';
import { setApplicationLoaded } from '../../actions/index.js';
import includesAll from '../../utils/helpers/includesAll';
import type { CurrentUser, AppState } from '../../utils/helpers/types';

export const initialLoadPermissionsComplete = (policiesQueried: string[]) =>
  Array.isArray(policiesQueried) &&
  policiesQueried.length > 0 &&
  includesAll(policiesQueried, [
    AVAILABLE_PERMISSIONS.EDIT_ANNOUNCEMENT,
    AVAILABLE_PERMISSIONS.VIEW_DASHBOARD,
    AVAILABLE_PERMISSIONS.VIEW_THREAD_REPORT,
    AVAILABLE_PERMISSIONS.VIEW_FEEDBACK,
  ]);

const useCheckUserLoaded = (
  currentUser: CurrentUser,
  getPoliciesComplete = false
) => {
  const dispatch = useDispatch();

  const ff_beta_authorization = useSelector(
    (state: AppState) => state.accountData.account.ff_beta_authorization
  );
  const userLoadedFromDistributor = useSelector(
    (state: AppState) => state.applicationState?.userLoadedFromDistributor
  );
  const applicationLoaded = useSelector(
    (state: AppState) => state.applicationState?.applicationLoaded
  );

  useEffect(() => {
    if (
      !currentUser ||
      !userLoadedFromDistributor ||
      !(currentUser?.accountPolicies.length > 0) ||
      // If FF is disabled, skip check of policies loaded
      // The policies are loaded through the socket (during user load)
      // getPoliciesComplete will never be true when FF is disabled
      (ff_beta_authorization && !getPoliciesComplete) ||
      // Must have all the required permissions checked before marked as loaded
      // Only check when FF enabled
      (ff_beta_authorization &&
        !initialLoadPermissionsComplete(currentUser?.accountPoliciesQueried)) ||
      // Prevent re-running this if the application is already loaded
      applicationLoaded
    )
      return;

    dispatch(setApplicationLoaded());
  }, [
    applicationLoaded,
    currentUser,
    ff_beta_authorization,
    getPoliciesComplete,
    userLoadedFromDistributor,
  ]);
};

export default useCheckUserLoaded;
