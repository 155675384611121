import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import HorizontalBarWidget from './HorizontalBarWidget';
import COLORS from '../../styles/colors';
import DoughnutWidget from './DoughnutWidget';
import SingleChannelWidget from './SingleChannelWidget';
import MultiGroupFlyouts from './MultiGroupFlyouts';
import {
  WidgetWrapper,
  DoubleColumnWrapper,
  SingleColumnWrapper,
} from './styles';
import UnclaimedMultiGroupWidgetContainer from './UnclaimedMultiGroupWidgetContainer';

const sortedGroups = (groups) => {
  return groups.sort((a, b) => {
    if (
      a.numUnclaimedThreads < b.numUnclaimedThreads ||
      a.numOpenThreads < b.numOpenThreads
    ) {
      return 1;
    }
    if (
      a.numUnclaimedThreads > b.numUnclaimedThreads ||
      a.numOpenThreads > b.numOpenThreads
    ) {
      return -1;
    }
    return 0;
  });
};

const ThreadWidgets = ({
  unclaimedTotal,
  unclaimedUpperBound,
  unclaimedGroups,
  openTotal,
  openUpperBound,
  openGroups,
  activeTotal,
  activeGroups,
  openConvoFlyoutOpen,
  setOpenConvoFlyoutOpen,
  setUnclaimedConvoFlyoutOpen,
  unclaimedConvoFlyoutOpen,
}) => {
  const unclaimedArrayToSort = [...unclaimedGroups.counts];
  const openArrayToSort = [...openGroups.counts];
  return (
    <WidgetWrapper>
      <SingleColumnWrapper>
        <UnclaimedMultiGroupWidgetContainer
          unclaimedGroups={sortedGroups(unclaimedArrayToSort)}
          unclaimedTotal={unclaimedTotal}
          setUnclaimedConvoFlyoutOpen={setUnclaimedConvoFlyoutOpen}
          unclaimedUpperBound={unclaimedUpperBound}
        />
      </SingleColumnWrapper>
      <DoubleColumnWrapper>
        <HorizontalBarWidget
          title={i18n.t('threads-Dashboard-openConversations', {
            defaultValue: 'Open Conversations',
          })}
          total={openTotal}
          upperBound={openUpperBound}
          graphData={sortedGroups(openArrayToSort)}
          graphDataName="groupName"
          barColor={COLORS.PK.GOLD_100}
          countKey="numOpenThreads"
          btnText={i18n.t('search-LabelBar-viewAll')}
          btnOnClick={() => setOpenConvoFlyoutOpen(true)}
          btnDataTestId="open-view-all-btn"
          breakpointWidth="100%"
        />
        {activeGroups.length === 1 ? (
          <SingleChannelWidget
            total={activeTotal}
            singleChannelWidgetData={activeGroups}
          />
        ) : (
          <DoughnutWidget
            title={i18n.t('threads-DougnutWidget-activeThreadHeader', {
              defaultValue: 'Active conversations across your channels',
            })}
            total={activeTotal}
            doughnutWidgetData={activeGroups}
            breakpointWidth="100%"
            emptyDoughnutMessage={i18n.t(
              'threads-DougnutWidget-noActiveThreads',
              {
                defaultValue: 'No active conversations!',
              }
            )}
          />
        )}
      </DoubleColumnWrapper>
      {openConvoFlyoutOpen || unclaimedConvoFlyoutOpen ? (
        <MultiGroupFlyouts
          openConvoFlyoutOpen={openConvoFlyoutOpen}
          setOpenConvoFlyoutOpen={setOpenConvoFlyoutOpen}
          unclaimedConvoFlyoutOpen={unclaimedConvoFlyoutOpen}
          setUnclaimedConvoFlyoutOpen={setUnclaimedConvoFlyoutOpen}
        />
      ) : null}
    </WidgetWrapper>
  );
};

ThreadWidgets.propTypes = {
  unclaimedTotal: PropTypes.number.isRequired,
  unclaimedUpperBound: PropTypes.number,
  unclaimedGroups: PropTypes.object.isRequired,
  openTotal: PropTypes.number.isRequired,
  openUpperBound: PropTypes.number,
  openGroups: PropTypes.object.isRequired,
  activeTotal: PropTypes.number.isRequired,
  activeGroups: PropTypes.array.isRequired,
  openConvoFlyoutOpen: PropTypes.bool.isRequired,
  setOpenConvoFlyoutOpen: PropTypes.func.isRequired,
  setUnclaimedConvoFlyoutOpen: PropTypes.func.isRequired,
  unclaimedConvoFlyoutOpen: PropTypes.bool.isRequired,
};

ThreadWidgets.defaultProps = {
  openUpperBound: undefined,
  unclaimedUpperBound: undefined,
};

export default ThreadWidgets;
