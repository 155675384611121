import styled from 'styled-components';
import THEMES from '../../styles/themes/app';
import FONTSIZE_THEMES from '../../styles/themes/fontSize/fontSize';
import { BREAKPOINT_LG } from '../../utils/helpers/constants';

export const WidgetWrapper = styled.section`
  display: flex;
  gap: 12px;
  @media screen and (max-width: ${BREAKPOINT_LG}) {
    flex-direction: column;
  }
`;

export const WidgetLoadingContainer = styled.div`
  width: 430px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SingleColumnWrapper = styled.div``;
export const DoubleColumnWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

/** ********************
 *
 *  TABLE STYLES
 *
 ********************* */

export const TableWidgetContainer = styled.div`
  max-width: 1314px;
  ${(props) => props?.customStyles?.()}
`;

export const Timestamp = styled.span`
  display: inline-block;
  font-size: ${FONTSIZE_THEMES.TIMESTAMP};
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${THEMES.FOREGROUND_HIGH};
  * {
    color: ${THEMES.FOREGROUND_HIGH};
  }
  ${(props) => props?.styles}
`;

export const TableLoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  height: 70%;
  width: 100%;
`;

export const TableHeaderContainer = styled.div`
  padding: 4px 0px 8px;
  display: flex;
  justify-content: space-between;
`;

export const TableParagraphWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const LeftAlignedItems = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
`;

export const RightAlignedItems = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: 4px;
  min-width: 200px;
`;

export const TableContainer = styled.div`
  padding: 0px 0px 64px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const TableColumnHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const ColumnHeaderOverflow = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const OscillatingTableRow = styled.tr`
  &:nth-child(even) {
    background: ${THEMES.BACKGROUND_QUATERNARY};
  }
`;

export const TableHeader = styled.th`
  width: 25%;
`;

export const TableRow = styled.td`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const NotFoundWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0px 64px;
`;
