import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import styled from 'styled-components';
import moment from 'moment';
import { useApolloClient } from '@apollo/client';
import { USER_ROLES, useOpenThreadsByUserFlyoutWidget } from 'client-lib';
import { useSelector } from 'react-redux';
import { Loading, Text } from '../../elements';
import Flyout from '../Flyout2/Flyout';
import OpenFlyoutUsers from './OpenFlyoutUsers';
import { Timestamp } from './styles';

const TOP_N = 100;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 500px;
`;

const SingleGroupFlyout = ({ openConvoFlyoutOpen, setOpenConvoFlyoutOpen }) => {
  const client = useApolloClient();
  const currentUser = useSelector((state) => state.session.currentUser);
  const groupId = useSelector(
    (state) => state.session?.dashboardsActiveGroupIds[0]
  );
  const isCurrentUserAdmin =
    currentUser.role.toLowerCase() === USER_ROLES.ADMINISTRATOR.toLowerCase();

  const {
    openThreadCountUpperBound,
    openThreadCountByUser,
    widgetsLoading: openWidgetLoading,
  } = useOpenThreadsByUserFlyoutWidget({
    client,
    isCurrentUserAdmin,
    groupId,
    TOP_N,
  });

  return (
    <Flyout
      isOpen={openConvoFlyoutOpen}
      title={i18n.t('threads-Dashboard-openConversations', {
        defaultValue: 'Open Conversations',
      })}
      onRequestClose={() => {
        setOpenConvoFlyoutOpen(false);
      }}
      buttonLabels={{ primary: i18n.t('settings-BusinessHours-close') }}
      buttonActions={{
        primary: () => setOpenConvoFlyoutOpen(false),
      }}
      subheader={
        <Text>
          {i18n.t('threads-Dashboard-flyoutSingleGroupDescription', {
            defaultValue:
              'Each user should close out conversations once they are done. If numbers are high, please contact the users by clicking their name to remind them to close the conversations.',
          })}
        </Text>
      }
    >
      <ContentWrapper>
        <Timestamp
          dataTestId="singleGroupFlyout-timeFrame-timestamp"
          styles={{ marginBottom: '12px', marginTop: '12px' }}
        >
          <b>
            {i18n.t('threads-Dashboard-lastUpdated', {
              defaultValue: 'Last Updated:',
            })}
          </b>{' '}
          {moment().format('MM/DD/YYYY h:mm a')}
        </Timestamp>
        {openWidgetLoading ? (
          <LoadingWrapper>
            <Loading size="sm" />
          </LoadingWrapper>
        ) : (
          <OpenFlyoutUsers
            users={openThreadCountByUser}
            total={openThreadCountUpperBound}
          />
        )}
      </ContentWrapper>
    </Flyout>
  );
};

SingleGroupFlyout.propTypes = {
  openConvoFlyoutOpen: PropTypes.bool.isRequired,
  setOpenConvoFlyoutOpen: PropTypes.func.isRequired,
};

export default SingleGroupFlyout;
