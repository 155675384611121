import React from 'react';
import PropTypes from 'prop-types';
import { contactName } from 'client-lib/src/lib/utils/helpers';
import i18n from 'i18n-js';
import {
  Table,
  TableHead,
  TableBody,
  Text,
  Loading,
  Heading3,
  InlineTextButton,
  Select,
  Heading2,
} from '../../elements';
import SortButton from '../../elements/Table/SortButton';
import FormattedDateTime from '../Common/FormattedDateTime';
import {
  ColumnHeaderOverflow,
  LeftAlignedItems,
  NotFoundWrapper,
  OscillatingTableRow,
  RightAlignedItems,
  TableColumnHeader,
  TableContainer,
  TableHeader,
  TableHeaderContainer,
  TableLoadingWrapper,
  TableParagraphWrapper,
  TableRow,
  TableWidgetContainer,
  Timestamp,
} from './styles';
import { formatTimeOrNA } from '../../utils/helpers/timeUtils';
import {
  ORDER_BY_OPTIONS,
  SORT_BY_TYPES,
  getTimeFrameOptions,
} from './constants';

const SingleGroupTable = ({
  handleRefetch,
  loading,
  data,
  sortType,
  orderBy,
  timeFrame,
  setTimeFrame,
  setOrderBy,
  setSortType,
  showRefresh,
  lastUpdated,
}) => {
  const handleSortColumn = (orderKey) => {
    if (orderKey === orderBy) {
      setSortType(
        sortType === SORT_BY_TYPES.asc ? SORT_BY_TYPES.desc : SORT_BY_TYPES.asc
      );
    } else {
      setOrderBy(orderKey);
      setSortType(SORT_BY_TYPES.asc);
    }
  };

  return (
    <TableWidgetContainer>
      <TableHeaderContainer>
        <LeftAlignedItems>
          <Heading3>
            {i18n.t('threads-Dashboard-userOverview', {
              defaultValue: 'User Overview',
            })}
          </Heading3>
          {showRefresh && (
            <TableParagraphWrapper>
              <Text>
                {i18n.t('threads-Dashboard-details', {
                  defaultValue:
                    'Data on this table does not automatically update.',
                })}{' '}
              </Text>
              <InlineTextButton
                dataTestId="singleGroup-refresh-button"
                noOutline
                onClick={handleRefetch}
              >
                {i18n.t('threads-Dashboard-refresh', {
                  defaultValue: 'Refresh Data',
                })}
              </InlineTextButton>
              <Timestamp data-testid="singleGroup-timeFrame-timestamp">
                {' '}
                {i18n.t('threads-Dashboard-lastUpdated', {
                  defaultValue: 'Last Updated:',
                })}{' '}
                <FormattedDateTime value={lastUpdated} />
              </Timestamp>
            </TableParagraphWrapper>
          )}
        </LeftAlignedItems>
        <RightAlignedItems>
          <Select
            data-testid="singleGroup-timeFrame-dropdown"
            options={getTimeFrameOptions(i18n)}
            value={timeFrame}
            onChange={(option) => setTimeFrame(option)}
            hideBottomSpace
          />
        </RightAlignedItems>
      </TableHeaderContainer>
      {loading ? (
        <TableLoadingWrapper>
          <Loading />
        </TableLoadingWrapper>
      ) : data?.singleGroupUserTableData?.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <tr>
                <TableHeader>
                  <TableColumnHeader>
                    <ColumnHeaderOverflow
                      title={i18n.t('settings-TeamTable-name')}
                    >
                      {i18n.t('settings-TeamTable-name')}
                    </ColumnHeaderOverflow>
                    <SortButton
                      data-testid="singleGroup-name-sort"
                      onClick={() => handleSortColumn(ORDER_BY_OPTIONS.name)}
                      sortType={sortType}
                      orderBy={orderBy}
                      orderKey={ORDER_BY_OPTIONS.name}
                    />
                  </TableColumnHeader>
                </TableHeader>
                <TableHeader>
                  <TableColumnHeader>
                    <ColumnHeaderOverflow
                      title={i18n.t('threads-Dashboard-avgTime', {
                        defaultValue: 'Average Time To Claim',
                      })}
                    >
                      {i18n.t('threads-Dashboard-avgTime', {
                        defaultValue: 'Average Time To Claim',
                      })}
                    </ColumnHeaderOverflow>
                    <SortButton
                      data-testid="singleGroup-avTime-sort"
                      onClick={() =>
                        handleSortColumn(ORDER_BY_OPTIONS.averageTimeToClaim)
                      }
                      sortType={sortType}
                      orderBy={orderBy}
                      orderKey={ORDER_BY_OPTIONS.averageTimeToClaim}
                    />
                  </TableColumnHeader>
                </TableHeader>
                <TableHeader>
                  <TableColumnHeader>
                    <ColumnHeaderOverflow
                      title={i18n.t('threads-Dashboard-claimedConv', {
                        defaultValue: 'Claimed Conversations',
                      })}
                    >
                      {i18n.t('threads-Dashboard-claimedConv', {
                        defaultValue: 'Claimed Conversations',
                      })}
                    </ColumnHeaderOverflow>
                    <SortButton
                      data-testid="singleGroup-claimed-sort"
                      onClick={() =>
                        handleSortColumn(ORDER_BY_OPTIONS.claimedConversations)
                      }
                      sortType={sortType}
                      orderBy={orderBy}
                      orderKey={ORDER_BY_OPTIONS.claimedConversations}
                    />
                  </TableColumnHeader>
                </TableHeader>
                <TableHeader>
                  <TableColumnHeader>
                    <ColumnHeaderOverflow
                      title={i18n.t('threads-Dashboard-numTransfers', {
                        defaultValue: '# of transfers',
                      })}
                    >
                      {i18n.t('threads-Dashboard-numTransfers', {
                        defaultValue: '# of transfers',
                      })}
                    </ColumnHeaderOverflow>
                    <SortButton
                      data-testid="singleGroup-avTime-sort"
                      onClick={() =>
                        handleSortColumn(ORDER_BY_OPTIONS.numTransfersInitiated)
                      }
                      sortType={sortType}
                      orderBy={orderBy}
                      orderKey={ORDER_BY_OPTIONS.numTransfersInitiated}
                    />
                  </TableColumnHeader>
                </TableHeader>
                <TableHeader>
                  <TableColumnHeader>
                    <ColumnHeaderOverflow
                      title={i18n.t('threads-Dashboard-closedConv', {
                        defaultValue: 'Closed Conversations',
                      })}
                    >
                      {i18n.t('threads-Dashboard-closedConv', {
                        defaultValue: 'Closed Conversations',
                      })}
                    </ColumnHeaderOverflow>
                    <SortButton
                      data-testid="singleGroup-closed-sort"
                      onClick={() =>
                        handleSortColumn(ORDER_BY_OPTIONS.closedThreads)
                      }
                      sortType={sortType}
                      orderBy={orderBy}
                      orderKey={ORDER_BY_OPTIONS.closedThreads}
                    />
                  </TableColumnHeader>
                </TableHeader>
              </tr>
            </TableHead>
            <TableBody>
              {data?.singleGroupUserTableData?.map((contact, index) => (
                <OscillatingTableRow key={index}>
                  <TableRow>{contact?.name || contactName(contact)}</TableRow>
                  <TableRow>
                    {formatTimeOrNA(contact?.averageTimeToClaim)}
                  </TableRow>
                  <TableRow>{contact?.claimedConversations}</TableRow>
                  <TableRow>{contact?.numTransfersInitiated}</TableRow>
                  <TableRow>{contact?.closedThreads}</TableRow>
                </OscillatingTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NotFoundWrapper>
          <Heading2>{timeFrame.otherProps.warning}</Heading2>
        </NotFoundWrapper>
      )}
    </TableWidgetContainer>
  );
};

SingleGroupTable.propTypes = {
  handleRefetch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  sortType: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  timeFrame: PropTypes.object.isRequired,
  setTimeFrame: PropTypes.func.isRequired,
  data: PropTypes.object,
  setOrderBy: PropTypes.func.isRequired,
  setSortType: PropTypes.func.isRequired,
  showRefresh: PropTypes.bool,
  lastUpdated: PropTypes.string,
};

SingleGroupTable.defaultProps = {
  data: null,
  showRefresh: false,
  lastUpdated: new Date().toISOString(),
};

export default SingleGroupTable;
