import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import moment from 'moment';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/client';
import {
  USER_ROLES,
  useOpenThreadsFlyoutWidget,
  useUnclaimedThreadsFlyoutWidget,
} from 'client-lib';
import { useSelector } from 'react-redux';
import { DEFAULT_DASHBOARD_FLYOUT_ROW_COUNT } from 'client-lib/src/lib/utils/helpers';
import { Heading5, IconButton, Loading, MetaText, Text } from '../../elements';
import THEMES from '../../styles/themes/app';
import Flyout2 from '../Flyout2/Flyout';
import UnclaimedFlyoutGroups from './UnclaimedFlyoutGroups';
import OpenFlyoutGroups from './OpenFlyoutGroups';
import { getColorTimeLabelObj } from './colorTimeLabelsData';
import { Timestamp } from './styles';

const ColorKeyList = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: ${THEMES.FOREGROUND_HIGH};
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 500px;
`;

const ColorKeyHeader = styled(Heading5)`
  margin-top: 0;
  margin-bottom: 0;
  color: ${THEMES.FOREGROUND_HIGH};
`;

const TOP_N = DEFAULT_DASHBOARD_FLYOUT_ROW_COUNT;

const MultiGroupFlyouts = ({
  openConvoFlyoutOpen,
  setOpenConvoFlyoutOpen,
  unclaimedConvoFlyoutOpen,
  setUnclaimedConvoFlyoutOpen,
}) => {
  const client = useApolloClient();
  const currentUser = useSelector((state) => state.session.currentUser);
  const groupIds = useSelector(
    (state) => state.session.dashboardsActiveGroupIds
  );
  const isCurrentUserAdmin =
    currentUser.role.toLowerCase() === USER_ROLES.ADMINISTRATOR.toLowerCase();

  const {
    openThreadCountUpperBound,
    openThreadCountByGroup,
    widgetsLoading: openLoading,
  } = useOpenThreadsFlyoutWidget({
    client,
    topN: TOP_N,
    isCurrentUserAdmin,
    groupIds,
  });

  const {
    unclaimedThreadCountTotal,
    unclaimedThreadCountByGroup,
    widgetsLoading: unclaimedLoading,
  } = useUnclaimedThreadsFlyoutWidget({ client, groupIds });

  const modalTitle = openConvoFlyoutOpen
    ? `${i18n.t('threads-Dashboard-openConversations', {
        defaultValue: 'Open Conversations',
      })} - ${i18n.t('settings-TeamMemberForm-groups')}`
    : i18n.t('dashboards-unclaimedConvoFlyout-allGroupsUnclaimed', {
        defaultValue: 'All Groups Unclaimed',
      });

  const flyoutText = openConvoFlyoutOpen
    ? i18n.t('threads-Dashboard-flyoutMultiGroupsDescription', {
        defaultValue:
          'Users should close out conversations once they have been resolved. If numbers are high, please contact the manager of the specific group to notify their users.',
      })
    : i18n.t('dashboards-unclaimedConvoFlyout-description', {
        defaultValue:
          'Users should claim conversations quickly to ensure that contacts receive timely responses. If a group has several old conversations, please contact the manager of the specific group to notify their users.',
      });

  const colorTimeLabelObj = getColorTimeLabelObj(i18n);

  const renderSubHead = (isUnclaimed) => (
    <>
      <Text>{flyoutText}</Text>
      {isUnclaimed && (
        <>
          <ColorKeyHeader>
            {i18n
              .t('dashboards-unclaimedConvoFlyout-colorKey', {
                defaultValue: 'Color Key',
              })
              .toUpperCase()}
          </ColorKeyHeader>
          <ColorKeyList>
            {colorTimeLabelObj.map((colorKey, index) => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '10px',
                  marginBottom: '10px',
                }}
                key={index}
              >
                <IconButton
                  dataTestId="label-card-button"
                  noOutline
                  size="sm"
                  style={{
                    backgroundColor: colorKey.color,
                    marginRight: '8px',
                  }}
                >
                  <i className="ri-square-fill" />
                </IconButton>
                <MetaText>{colorKey.label}</MetaText>
              </div>
            ))}
          </ColorKeyList>
        </>
      )}
    </>
  );

  return (
    <Flyout2
      isOpen={openConvoFlyoutOpen || unclaimedConvoFlyoutOpen}
      title={modalTitle}
      onRequestClose={() => {
        setOpenConvoFlyoutOpen(false) || setUnclaimedConvoFlyoutOpen(false);
      }}
      titleIcon=""
      buttonLabels={{ primary: i18n.t('settings-BusinessHours-close') }}
      buttonActions={{
        primary: () => {
          setOpenConvoFlyoutOpen(false) || setUnclaimedConvoFlyoutOpen(false);
        },
      }}
      subheader={renderSubHead(unclaimedConvoFlyoutOpen)}
    >
      {openLoading || unclaimedLoading ? (
        <LoadingWrapper>
          <Loading size="sm" />
        </LoadingWrapper>
      ) : (
        <div>
          <Timestamp
            dataTestId="multiGroupFlyout-timeFrame-timestamp"
            styles={{ marginTop: '12px', marginBottom: '12px' }}
          >
            <b>
              {i18n.t('threads-Dashboard-lastUpdated', {
                defaultValue: 'Last Updated:',
              })}
            </b>{' '}
            {moment().format('MM/DD/YYYY h:mm a')}
          </Timestamp>
          {unclaimedConvoFlyoutOpen ? (
            <UnclaimedFlyoutGroups
              groups={unclaimedThreadCountByGroup}
              total={unclaimedThreadCountTotal}
            />
          ) : (
            <OpenFlyoutGroups
              groups={openThreadCountByGroup}
              total={openThreadCountUpperBound}
            />
          )}
        </div>
      )}
    </Flyout2>
  );
};

MultiGroupFlyouts.propTypes = {
  openConvoFlyoutOpen: PropTypes.bool.isRequired,
  setOpenConvoFlyoutOpen: PropTypes.func.isRequired,
  unclaimedConvoFlyoutOpen: PropTypes.bool.isRequired,
  setUnclaimedConvoFlyoutOpen: PropTypes.func.isRequired,
};

export default MultiGroupFlyouts;
