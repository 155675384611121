import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import theme from 'styled-theming';
import i18n from 'i18n-js';
import { PROKEEP_SUPPORT_LINK } from '../../utils/helpers/constants';
import THEMES from '../../styles/themes/app';
import {
  Anchor,
  Heading2,
  MetaText,
  Text,
  Widget,
  Avatar,
} from '../../elements';

const CONVERATIONWRAP_MAXWIDTH = theme('fontSize', {
  default: 'max-content',
  large: 'min-content',
});

const CONVERATIONSWRAP_MARGIN = theme('fontSize', {
  default: '14px',
  large: '0',
});

const CONVERATIONSWRAP_PADDING_BOTTOM = theme('fontSize', {
  default: '18.5px',
  large: '10.5px',
});

const CONVERATIONSWRAP_MARGIN_BOTTOM = theme('fontSize', {
  default: '27px',
  large: '10px',
});

const ANCHORWRAPPER_GAP = theme('fontSize', {
  default: '4px',
  large: '16px',
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const ConversationsWrap = styled.div`
  width: 100%;
  justify-content: space-evenly;
  border-bottom: 1px solid ${THEMES.BORDER_COLOR};
  margin-top: ${CONVERATIONSWRAP_MARGIN};
  display: flex;
  gap: 12px;
  padding-bottom: ${CONVERATIONSWRAP_PADDING_BOTTOM};
  margin-bottom: ${CONVERATIONSWRAP_MARGIN_BOTTOM};
`;

const ConversationWrap = styled.div`
  max-width: ${CONVERATIONWRAP_MAXWIDTH};
`;

const AnchorWrapper = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: start;
  justify-content: center;
  gap: ${ANCHORWRAPPER_GAP};
`;

const ChannelContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-direction: column;
  text-align: center;
  width: 100px;
`;

const SingleChannelWidget = ({ singleChannelWidgetData, total }) => {
  const [channelData] = singleChannelWidgetData;
  const {
    otherProps: { inboundCount, outboundCount = null },
  } = channelData;

  const channelOptions = [
    {
      icon: 'smsChannel',
      copy: i18n.t('modals-BlockOrRemove-channelOption-sms', {
        defaultValue: 'Text',
      }),
      identifier: 'text',
    },
    {
      icon: 'faxChannel',
      copy: i18n.t('slideouts-CustomerInfo-fax', { defaultValue: 'Fax' }),
      identifier: 'fax',
    },
    {
      icon: 'webchatChannel',
      copy: i18n.t('settings-WebConnect-webChat', {
        defaultValue: 'Web Chat',
      }),
      identifier: 'webchat',
    },
    {
      icon: 'emailChannel',
      copy: i18n.t('modals-BlockOrRemove-channelOption-email', {
        defaultValue: 'Email',
      }),
      identifier: 'email',
    },
  ];

  const filteredChannelOptions = channelOptions.filter(
    (channelOption) => channelOption.identifier !== channelData.key
  );

  const singleChannelTitle = `${i18n.t(
    'threads-DashboardSingleChannel-header',
    {
      defaultValue: `Active conversations across`,
    }
  )} ${channelData.label}`;

  return (
    <Widget title={singleChannelTitle} total={total} breakpointWidth="100%">
      <Container>
        <ConversationsWrap>
          <ConversationWrap>
            <Heading2>{inboundCount}</Heading2>
            <Text
              contrast="low"
              customStyle={() => 'text-transform: capitalize;'}
            >
              {i18n.t('threads-DashboardSingleChannel-inboundConversations', {
                defaultValue: 'Inbound Conversations',
              })}
            </Text>
          </ConversationWrap>
          {outboundCount !== null && (
            <ConversationWrap>
              <Heading2>{outboundCount}</Heading2>
              <Text
                contrast="low"
                customStyle={() => 'text-transform: capitalize;'}
              >
                {i18n.t(
                  'threads-DashboardSingleChannel-outboundConversations',
                  { defaultValue: 'Outbound Conversations' }
                )}
              </Text>
            </ConversationWrap>
          )}
        </ConversationsWrap>
        <MetaText contrast="low">
          {i18n.t('threads-DashboardSingleChannel-prokeepOffersOtherChannels', {
            defaultValue: 'Prokeep offers other channels like',
          })}
          :
        </MetaText>
        <AnchorWrapper>
          {filteredChannelOptions.map((channel, index) => (
            <ChannelContainer key={index}>
              <Avatar icon={channel.icon} size="sm" />
              <Anchor href={PROKEEP_SUPPORT_LINK} target="_blank">
                <MetaText contrast="low">{channel.copy}</MetaText>
              </Anchor>
            </ChannelContainer>
          ))}
        </AnchorWrapper>
      </Container>
    </Widget>
  );
};

SingleChannelWidget.propTypes = {
  total: PropTypes.number.isRequired,
  singleChannelWidgetData: PropTypes.arrayOf(Object).isRequired,
};

export default SingleChannelWidget;
