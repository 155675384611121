import moment from 'moment';
import 'moment-duration-format';

const formatTimeOrNA = (timeInSeconds) => {
  if (timeInSeconds === -1) return 'N/A';

  const duration = moment.duration(timeInSeconds, 'seconds');
  return timeInSeconds < 60
    ? duration.format('mm:ss', { trim: false })
    : duration.format('HH:mm:ss');
};

export { formatTimeOrNA };
