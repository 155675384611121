import React from 'react';
import { AVAILABLE_PERMISSIONS, useUserGroups } from 'client-lib';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Button } from '../../../elements';
import useGetUserAccountPolicies from '../../../hooks/customer/useGetUserAccountPolicies';
import FormattedDateTime from '../../Common/FormattedDateTime';
import GroupRegionSelectFlyout from '../../Dashboard/GroupRegionSelectFlyout';
import {
  TableParagraphWrapper,
  TableWidgetContainer,
  Timestamp,
} from '../../Dashboard/styles';

const ActionBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RefreshDataContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const LastUpdatedLabel = styled.span`
  font-weight: bold;
`;

const ActionBar = ({
  activeGroupIds,
  completedDate,
  customStyles,
  handleGroupsUpdate,
  handleRefetch,
  loading,
}) => {
  const currentUser = useSelector((state) => state.session.currentUser);
  const allGroups = useSelector((state) => state.accountData.allGroups);
  const { groups } = useUserGroups(currentUser, allGroups);

  const { [AVAILABLE_PERMISSIONS.READ_REGIONS]: regionAccess } =
    useGetUserAccountPolicies({
      actionList: [AVAILABLE_PERMISSIONS.READ_REGIONS],
      userId: currentUser?.userId,
    });

  return (
    <TableWidgetContainer customStyles={customStyles}>
      <ActionBarContainer>
        {groups.length > 1 && (
          <GroupRegionSelectFlyout
            activeGroupIds={activeGroupIds}
            onSelectionFinal={handleGroupsUpdate}
            allowRegions={regionAccess}
          />
        )}
        <RefreshDataContainer>
          <TableParagraphWrapper>
            <Timestamp data-testid="singleGroup-timeFrame-timestamp">
              <LastUpdatedLabel>
                {i18n.t('threads-Dashboard-lastUpdated', {
                  defaultValue: 'Last Updated:',
                })}{' '}
              </LastUpdatedLabel>
              <FormattedDateTime value={completedDate} showDate />
            </Timestamp>
          </TableParagraphWrapper>
          <Button
            onClick={loading ? null : handleRefetch}
            disabled={loading}
            data-testid="refresh-data-button"
          >
            {i18n.t('threads-Dashboard-refresh', {
              defaultValue: 'Refresh Data',
            })}
          </Button>
        </RefreshDataContainer>
      </ActionBarContainer>
    </TableWidgetContainer>
  );
};

ActionBar.propTypes = {
  activeGroupIds: PropTypes.array,
  completedDate: PropTypes.string,
  customStyles: PropTypes.func,
  handleGroupsUpdate: PropTypes.func.isRequired,
  handleRefetch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

ActionBar.defaultProps = {
  activeGroupIds: [],
  completedDate: new Date().toISOString(),
  customStyles: () => {},
};

export default ActionBar;
