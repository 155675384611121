import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useApolloClient, useQuery } from '@apollo/client';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  AVAILABLE_PERMISSIONS,
  VERIFICATION_STATUS_QUERY,
  checkIfCurrentUserHasPermission,
  useJoinTeam,
} from 'client-lib';
import i18n from 'i18n-js';
import { signOut, resetStore } from '../../actions';
import { Message, MessageContainer } from '../../components/Settings/styles';
import PasswordRequirements from './PasswordRequirements';
import { Heading1, Text, Loading, Button, TextInput } from '../../elements';
import { Container } from './styles';
import { navigateHome } from '../../components/Sidebar/utils';

const OuterHeaderWrapper = styled.div`
  margin-bottom: 24px;
`;

const InnterHeaderWrapper = styled.div`
  margin-bottom: 8px;
`;
const TextWrapper = styled.div`
  margin-left: 15px;
`;

const JoinTeam = () => {
  const client = useApolloClient();
  const history = useHistory();
  const {
    params: { token },
  } = useRouteMatch();
  const dispatch = useDispatch();

  const [passesValidations, setPassesValidations] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { loading: verifyLoading, data: verificationAssertStatus } = useQuery(
    VERIFICATION_STATUS_QUERY,
    {
      client,
      variables: { verificationToken: token },
    }
  );

  const handleVerificationStatusSucceed = () => {
    history.push('/login');
  };

  const handleJoinTeamSucceed = (joinTeam) => {
    const sessionUser = joinTeam.session.user;
    client.disconnectSocket();
    dispatch(signOut());
    dispatch(resetStore());
    const absintheLink = client.connectSocket(
      sessionUser.id,
      sessionUser.accountId
    );

    absintheLink.onPresenceJoin((user) => {
      setIsSubmitting(false);

      // PRKP-4829 When new lazy loading is
      // enabled, need to wait for accountPolicies, won't
      // be available from the socket any more.
      if (!user.ff_beta_authorization) {
        const accountPolicies = user.account_policies.map((policy) => ({
          accountId: policy.account_id,
          actions: policy.actions,
        }));

        const groupPolicies = user.group_policies.map((policy) => ({
          groupId: policy.group_id,
          actions: policy.actions,
        }));

        const hasDashboardPermission = checkIfCurrentUserHasPermission(
          AVAILABLE_PERMISSIONS.VIEW_DASHBOARD,
          accountPolicies,
          groupPolicies
        );

        const hasFeedbackPermission = checkIfCurrentUserHasPermission(
          AVAILABLE_PERMISSIONS.VIEW_FEEDBACK,
          accountPolicies,
          groupPolicies
        );

        navigateHome(hasDashboardPermission, hasFeedbackPermission, history);
      } else {
        // PRKP-4829 Need to clear the current route,
        //   or after setSessionLoading gets set to false,
        //   will still be on the /join-team route.
        // No need to specify dashboard/inbox, other decision code
        //  takes care of this.
        history.push('/');
      }
    });
  };

  const { loading, onSubmit, updateField, fields, isVisitedForm, error } =
    useJoinTeam({
      client,
      handleJoinTeamSucceed,
      handleVerificationStatusSucceed,
      verificationToken: token,
      i18n,
    });

  const { firstName, lastName, password, passwordConfirm } = fields;

  const isSubmitButtonDisabled = !(
    isVisitedForm &&
    passesValidations &&
    firstName.value.length &&
    lastName.value.length &&
    !isSubmitting
  );

  useEffect(() => {
    if (error) setIsSubmitting(false);
  }, [error]);

  if (verifyLoading) {
    return (
      <div>
        <Loading size="sm" />
      </div>
    );
  }

  const handleSubmitForm = () => {
    setIsSubmitting(true);
    onSubmit();
  };

  if (
    verificationAssertStatus &&
    verificationAssertStatus.verificationStatus &&
    verificationAssertStatus.verificationStatus.status === 'PENDING'
  ) {
    return (
      <Container data-testid="join-team" style={{ margin: 'auto' }}>
        {!loading && (
          <div style={{ padding: '10px 0' }}>
            <OuterHeaderWrapper>
              <InnterHeaderWrapper>
                <Heading1>{i18n.t('signin-JoinTeam-welcomeAboard')}</Heading1>
              </InnterHeaderWrapper>
              {error && (
                <MessageContainer>
                  <Message className="active">{error}</Message>
                </MessageContainer>
              )}
              <Text>{i18n.t('signin-JoinTeam-fillInfoBelow')}</Text>
            </OuterHeaderWrapper>
            <div>
              <TextInput
                label={i18n.t('signin-JoinTeam-firstName')}
                error={firstName.error}
                dataTestId="firstname-input"
                value={firstName.value}
                onChange={(ev) =>
                  updateField({ value: ev.target.value, name: 'firstName' })
                }
                onFocus={() => firstName.setFocus(true)}
                onBlur={() => firstName.setFocus(false)}
              />
            </div>
            <div>
              <TextInput
                label={i18n.t('signin-JoinTeam-lastName')}
                dataTestId="lastname-input"
                error={lastName.error}
                value={lastName.value}
                onChange={(ev) =>
                  updateField({ value: ev.target.value, name: 'lastName' })
                }
                onFocus={() => lastName.setFocus(true)}
                onBlur={() => lastName.setFocus(false)}
              />
            </div>
            <div>
              <TextInput
                type="password"
                label={i18n.t('signin-JoinTeam-password')}
                dataTestId="password-input"
                error={password.error}
                value={password.value}
                onChange={(ev) =>
                  updateField({ value: ev.target.value, name: 'password' })
                }
                onFocus={() => password.setFocus(true)}
                onBlur={() => password.setFocus(false)}
              />
            </div>
            <div>
              <TextInput
                type="password"
                label={i18n.t('settings-PasswordForm-passwordMatch')}
                dataTestId="password-confirm-input"
                error={passwordConfirm.error}
                value={passwordConfirm.value}
                onChange={(ev) =>
                  updateField({
                    value: ev.target.value,
                    name: 'passwordConfirm',
                  })
                }
                onFocus={() => passwordConfirm.setFocus(true)}
                onBlur={() => passwordConfirm.setFocus(false)}
              />
              <PasswordRequirements
                password={password.value}
                passwordConfirm={passwordConfirm.value}
                onValidate={setPassesValidations}
              />
            </div>
            <div>
              <Button
                onClick={handleSubmitForm}
                disabled={isSubmitButtonDisabled}
                loadingSpinner={isSubmitting}
              >
                {i18n.t('signin-JoinTeam-save')}
              </Button>
            </div>
          </div>
        )}
      </Container>
    );
  }

  return (
    <div>
      <TextWrapper>
        <Text>{i18n.t('signin-JoinTeam-inviteAlreadyAccepted')}</Text>
      </TextWrapper>
      <br />
      <br />
      <Button type="link" onClick={() => history.push('/login')}>
        {i18n.t('signin-JoinTeam-signIn')}
      </Button>
    </div>
  );
};

export default JoinTeam;
