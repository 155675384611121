import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { sortedGroupThreadsByTime } from 'client-lib/src/lib/utils/helpers';
import i18n from 'i18n-js';
import uniqid from 'uniqid';
import styled from 'styled-components';
import HorizontalBarWidget from './HorizontalBarWidget';
import { getColorCodes, getColorTimeLabelObj } from './colorTimeLabelsData';
import COLORS from '../../styles/colors';
import { Heading4, IconButton, Text } from '../../elements';
import FONTSIZE_THEMES from '../../styles/themes/fontSize/fontSize';

const ColorKeyList = styled.div`
  padding: 8px;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
`;

const ColorLabel = styled(Text)`
  font-size: ${FONTSIZE_THEMES.TEXT};
`;

const BubbleChildren = () => (
  <ColorKeyList>
    <Heading4 customStyle={() => `font-weight: 700;`}>
      {i18n.t('dashboards-MutliColoredBar-conversationColors', {
        defaultValue: 'Conversation Colors',
      })}
    </Heading4>
    {getColorTimeLabelObj(i18n).map((colorKey, index) => (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
        key={index}
      >
        <IconButton
          dataTestId="label-card-button"
          noOutline
          size="sm"
          style={{
            backgroundColor: colorKey.color,
            marginRight: '8px',
          }}
        >
          <i className="ri-square-fill" />
        </IconButton>
        <ColorLabel>{colorKey.label}</ColorLabel>
      </div>
    ))}
  </ColorKeyList>
);

const UnclaimedMultiGroupWidgetContainer = ({
  unclaimedTotal,
  unclaimedGroups,
  setUnclaimedConvoFlyoutOpen,
  unclaimedUpperBound,
}) => {
  const [key, setKey] = useState(0);

  useEffect(() => {
    /**
     * The BE doesn't handle checking if threads need to move
     * between bars. Dwight and I decided to force the bars to refresh
     * every 10 seconds, in case there are long periods without updates
     * from subscription, this will stay accurate with low latency
     */
    const renderInterval = setInterval(() => {
      const id = uniqid();
      setKey(id);
    }, 10000);
    return () => {
      clearInterval(renderInterval);
    };
  }, []);

  return (
    <HorizontalBarWidget
      key={key}
      total={unclaimedTotal}
      upperBound={unclaimedUpperBound}
      graphData={unclaimedGroups}
      graphDataName="groupName"
      title={i18n.t('threads-Dashboard-unclaimedConversations', {
        defaultValue: 'Unclaimed Conversations',
      })}
      barColor={COLORS.PK_CLASSIC.GRAPH_RED}
      barColors={getColorCodes(i18n)}
      countKey="numUnclaimedThreads"
      breakpointWidth="100%"
      btnText={i18n.t('search-LabelBar-viewAll')}
      btnOnClick={() => {
        setUnclaimedConvoFlyoutOpen(true);
      }}
      btnDataTestId="open-view-all-btn-multigroup-unclaimed-conversations"
      formatMultiColorDataCallback={(group) =>
        sortedGroupThreadsByTime(group, getColorCodes(i18n))
      }
      bubbleChildren={<BubbleChildren />}
    />
  );
};

UnclaimedMultiGroupWidgetContainer.propTypes = {
  unclaimedGroups: PropTypes.array.isRequired,
  unclaimedTotal: PropTypes.number.isRequired,
  setUnclaimedConvoFlyoutOpen: PropTypes.func.isRequired,
  unclaimedUpperBound: PropTypes.number,
};

UnclaimedMultiGroupWidgetContainer.defaultProps = {
  unclaimedUpperBound: null,
};

export default UnclaimedMultiGroupWidgetContainer;
