import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  AVAILABLE_PERMISSIONS,
  checkIfCurrentUserHasPermission,
  useBackupQueryThreads,
  useQueryThreads,
} from 'client-lib';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import Fade from '@mui/material/Fade';
import SnackbarContent from '@mui/material/SnackbarContent';
import i18n from 'i18n-js';
import Snackbar from '@mui/material/Snackbar';
import styled, { ThemeContext } from 'styled-components';
import CreateSection from '../CreateSection/CreateSection';
import Sidebar from '../Sidebar';
import GlobalModalsContainer from '../GlobalModalsContainer/GlobalModalsContainer';
import ThreadNotificationContainer from '../../containers/ThreadNotificationContainer';
import useSignedInWebApp from '../../hooks/useSignedInWebApp';
import Routes from '../../Routes';
import LazyFallback from '../LazyFallback/LazyFallback';
import useGetAllAccountPolicies from '../../hooks/signedIn/useGetAllAccountPolicies';
import useCheckUserLoaded, {
  initialLoadPermissionsComplete,
} from '../../hooks/signedIn/useCheckUserLoaded.ts';
import useDirectUserToIntendedUriAfterLogin from '../../hooks/signedIn/useDirectUserToIntendedUriAfterLogin';
import { closeSnackbar } from '../../actions/general';
import { Button, Text } from '../../elements';
import THEMES from '../../styles/themes/app';

const SnackbarContentsWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const CheckIcon = styled.i`
  padding-right: 8px;
  font-size: 22px;
`;
const LinkButton = styled.div`
  padding-left: 8px;
`;
const TruncateOverflowWrapper = styled.span`
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 24px;
  max-width: 50vw;
`;

const SignedInApp = () => {
  const history = useHistory();
  useSignedInWebApp({ history });
  const dispatch = useDispatch();
  const client = useApolloClient();
  const store = useStore();
  const styledTheme = useContext(ThemeContext);

  // PRKP-4829 Temporary pull of all account policies.
  // To be removed after JIT permissions are replaced
  // throughout the app.
  // Only runs when FF is enabled.
  const getPoliciesComplete = useGetAllAccountPolicies();

  const currentUser = useSelector(
    (state) => state.session.currentUser,
    (currentUser, prev) =>
      currentUser?.userId === prev?.userId &&
      initialLoadPermissionsComplete(currentUser.accountPoliciesQueried) ===
        initialLoadPermissionsComplete(prev.accountPoliciesQueried)
  );
  useCheckUserLoaded(currentUser, getPoliciesComplete);
  useDirectUserToIntendedUriAfterLogin();

  const includeInternal = useSelector(
    (state) => state.accountData.account?.ff_internal_threads
  );

  const threadsActiveGroupIds = useSelector(
    (state) => state?.session?.threadsActiveGroupIds
  );

  const socketConnectionTimestamp = useSelector(
    (state) => state?.session?.socketConnectionTimestamp
  );

  const applicationLoaded = useSelector(
    (state) => state.applicationState?.applicationLoaded
  );

  const ff_spam_filtering = useSelector(
    (state) => state?.accountData?.account.ff_spam_filtering
  );

  const general = useSelector((state) => state?.general);
  const { snackType, snackAutoHide } = general;
  const autoHideDuration = snackAutoHide ? 4000 : null;

  const announcementsPagePermission = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.EDIT_ANNOUNCEMENT,
    currentUser?.accountPolicies || [],
    currentUser?.groupPolicies || []
  );

  const reportsPagePermission = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.VIEW_THREAD_REPORT,
    currentUser?.accountPolicies || [],
    currentUser?.groupPolicies || []
  );

  const dashboardPagePermission = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.VIEW_DASHBOARD,
    currentUser?.accountPolicies || [],
    currentUser?.groupPolicies || []
  );

  const feedbackPagePermission = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.VIEW_FEEDBACK,
    currentUser?.accountPolicies || [],
    currentUser?.groupPolicies || []
  );

  const {
    allClosedThreadsQueryHandling,
    allOpenThreadsQueryHandling,
    myClosedThreadsQueryHandling,
    myOpenThreadsQueryHandling,
    myInboxThreadsQueryHandling,
    inboxSpamThreadsQueryHandling,
  } = useQueryThreads({
    client,
    currentUser,
    threadsActiveGroupIds,
    store,
    socketConnectionTimestamp,
    includeInternal,
    includeSpam: ff_spam_filtering,
  });

  useBackupQueryThreads({
    client,
    currentUser,
    threadsActiveGroupIds,
    includeInternal,
  });

  const getSnackColor = useMemo(() => {
    if (snackType === 'warning') {
      return THEMES.THEME_YELLOW({ theme: styledTheme });
    } else if (snackType === 'error') {
      return THEMES.THEME_RED({ theme: styledTheme });
    }

    return THEMES.THEME_GREEN({ theme: styledTheme });
  }, [snackType, styledTheme]);

  const getSnackIcon = useMemo(() => {
    if (snackType === 'warning') {
      return 'ri-indeterminate-circle-line';
    } else if (snackType === 'error') {
      return 'ri-error-warning-line';
    }
    return 'ri-checkbox-circle-line';
  }, [snackType]);

  return applicationLoaded ? (
    <>
      <Sidebar
        announcementsPagePermission={announcementsPagePermission}
        reportsPagePermission={reportsPagePermission}
        dashboardPagePermission={dashboardPagePermission}
        feedbackPagePermission={feedbackPagePermission}
      />
      <ThreadNotificationContainer />
      <CreateSection />
      <GlobalModalsContainer />

      <Routes
        allClosedThreadsQueryHandling={allClosedThreadsQueryHandling}
        allOpenThreadsQueryHandling={allOpenThreadsQueryHandling}
        announcementsPagePermission={announcementsPagePermission}
        dashboardPagePermission={dashboardPagePermission}
        feedbackPagePermission={feedbackPagePermission}
        includeInternal={includeInternal}
        myClosedThreadsQueryHandling={myClosedThreadsQueryHandling}
        myInboxThreadsQueryHandling={myInboxThreadsQueryHandling}
        inboxSpamThreadsQueryHandling={inboxSpamThreadsQueryHandling}
        myOpenThreadsQueryHandling={myOpenThreadsQueryHandling}
        reportsPagePermission={reportsPagePermission}
      />

      <Snackbar
        style={{
          top: '0',
          bottom: 'auto',
          left: '50%',
          maxWidth: 'none',
          zIndex: '1000',
          transform: general.snackbarOpen
            ? 'translate3d(-50%, 0, 0)'
            : 'translate3d(-50, -5px, 0)',
        }}
        open={general.snackbarOpen}
        autoHideDuration={autoHideDuration}
        TransitionComponent={Fade}
        onClose={() => dispatch(closeSnackbar())}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <SnackbarContent
          message={
            <SnackbarContentsWrapper>
              <CheckIcon className={getSnackIcon} />
              <Text contrast="colorHigh">
                <TruncateOverflowWrapper>
                  {general.snackMessage}
                </TruncateOverflowWrapper>
              </Text>
              {general.snackButtonLink ? (
                <LinkButton>
                  <Button
                    noOutline
                    customStyle={(props) => `
                              padding: 0px;
                              display: block;
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                              max-width: 100%;
                              color: ${THEMES.BACKGROUND_PRIMARY(props)};
                          `}
                    type="link"
                    onClick={() => {
                      history.push({
                        pathname: general.snackButtonLink,
                      });
                      dispatch(closeSnackbar());
                    }}
                  >
                    {i18n.t('threads-GlobalHandlers-viewNowLink')}
                  </Button>
                </LinkButton>
              ) : null}
            </SnackbarContentsWrapper>
          }
          style={{
            backgroundColor: getSnackColor,
            width: '100%',
            minWidth: 'initial',
            display: 'flex',
          }}
        />
      </Snackbar>
    </>
  ) : (
    <LazyFallback />
  );
};

export default SignedInApp;
