import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import FeedbackTable from './FeedbackTable';
import FeedbackActionBar from '../DashboardAndFeedback/ActionBar/FeedbackActionBar';

const Wrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  padding: 0 24px 0 24px;
`;

const Feedback = () => {
  const feedbackActiveGroupIds = useSelector(
    (state) => state.session?.feedbackActiveGroupIds
  );

  const [isRefetching, setIsRefetching] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(new Date().toISOString());

  const handleRefetch = () => {
    setLastUpdated(new Date().toISOString());
    setIsRefetching(true);
  };

  return (
    <Wrapper>
      <FeedbackActionBar
        activeGroupIds={feedbackActiveGroupIds}
        handleRefetch={handleRefetch}
        loading={false}
        completedDate={lastUpdated}
      />
      <FeedbackTable
        activeGroupIds={feedbackActiveGroupIds}
        isRefetching={isRefetching}
        setIsRefetching={setIsRefetching}
      />
    </Wrapper>
  );
};

export default Feedback;
