import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import i18n from 'i18n-js';
import {
  formatDataTestName,
  sortedGroupThreadsByTime,
} from 'client-lib/src/lib/utils/helpers';
import THEMES from '../../styles/themes/app';
import { Text } from '../../elements';
import { MultiColoredBar } from './HorizontalBarWidget';
import { getColorCodes } from './colorTimeLabelsData';

const GraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowContainer = styled.div`
  margin: 16px 0;
  width: 100%;
`;

const StackedBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 8px;
  max-width: 500px;
  flex: auto;
`;

const StackedBarBackground = styled.div`
  height: 8px;
  background-color: ${THEMES.BORDER_COLOR};
  border-radius: 4px;
  width: 100%;
`;

const BarDataContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const CountWrapper = styled.div`
  min-width: 24px; // attempt to stop the graphs from jumping
`;

const NameText = styled(Text)`
  width: 150px;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Wrapper = styled.div`
  display: flex;
  flex: auto;
`;
/**
 * Similar to the HorizontalBar in HorizontalBarWidget
 * but the styles for the widgets need to be different enough
 * that it was easier to make a new version for flyouts
 * than try to force 1 to work for both instances
 */

export const FlyoutHorizontalBar = ({ name, count, total, groupData }) => {
  return (
    <BarDataContainer>
      <NameText>{name}</NameText>
      <Wrapper>
        <StackedBarContainer>
          <StackedBarBackground>
            <MultiColoredBar
              name={name}
              total={total}
              data={groupData}
              barColors={getColorCodes(i18n)}
            />
          </StackedBarBackground>
        </StackedBarContainer>
        <CountWrapper>
          <Text
            dataTestId={`unclaimed-flyout-horizontal-bar-count-${formatDataTestName(
              name
            )}`}
          >
            {count}
          </Text>
        </CountWrapper>
      </Wrapper>
    </BarDataContainer>
  );
};

FlyoutHorizontalBar.propTypes = {
  name: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  groupData: PropTypes.array.isRequired,
  barColors: PropTypes.array.isRequired,
};

const UnclaimedFlyoutGroups = ({ groups }) => {
  return (
    <GraphWrapper>
      {groups?.counts?.map((group, i) => (
        <RowContainer key={i}>
          <FlyoutHorizontalBar
            total={groups?.counts?.[0]?.numUnclaimedThreads}
            count={group.numUnclaimedThreads}
            name={group.groupName}
            groupData={sortedGroupThreadsByTime(group, getColorCodes(i18n))}
            barColors={getColorCodes(i18n)}
          />
        </RowContainer>
      ))}
    </GraphWrapper>
  );
};

UnclaimedFlyoutGroups.propTypes = {
  groups: PropTypes.object,
  total: PropTypes.number,
};

UnclaimedFlyoutGroups.defaultProps = {
  groups: null,
  total: undefined,
};

export default UnclaimedFlyoutGroups;
