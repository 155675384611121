import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { formatDataTestName } from 'client-lib/src/lib/utils/helpers';
import THEMES from '../../styles/themes/app';
import { InlineTextButton, Text, Widget } from '../../elements';
import COLORS from '../../styles/colors';
import { BREAKPOINT_LG } from '../../utils/helpers/constants';
import Bubble from '../Common/Bubble';

const GraphContainer = styled.section`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  gap: 12px;
`;

const StackedBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 8px;
  max-width: 250px;
  @media screen and (max-width: ${BREAKPOINT_LG}) {
    max-width: 1044px;
    flex: auto;
  }
`;

const StackedBarBackground = styled.div`
  height: 8px;
  width: 250px;
  background-color: ${THEMES.BORDER_COLOR};
  border-radius: 4px;
  overflow: hidden; // creates border-radius for first color
  @media screen and (max-width: ${BREAKPOINT_LG}) {
    width: 100%;
  }
`;

const StackedBarValue = styled.div`
  border-radius: 4px;
  width: ${(props) => props.fillpercentage}%;
  max-width: 100%;
  height: 100%;
  background-color: ${(props) => props.barColor};
`;

const BarDataContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const CountWrapper = styled.div`
  min-width: 24px; // attempt to stop the graphs from jumping
`;

const NameText = styled(Text)`
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (max-width: ${BREAKPOINT_LG}) {
    width: 150px;
    max-width: 150px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  @media screen and (max-width: ${BREAKPOINT_LG}) {
    flex: auto;
  }
`;

const SecondaryTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const MultiColoredBar = ({
  name,
  data,
  total,
  barColors,
  dataTestId,
}) => {
  let totalCount = 0;
  const calculateFillPercentage = (count) => {
    totalCount += count;
    return (totalCount / total) * 100;
  };

  return (
    <div
      style={{
        width: '100%',
        height: '8px', // Set your desired height
        position: 'relative',
      }}
    >
      {data.map(({ count }, index) =>
        count ? (
          <div
            key={index}
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              height: '100%',
              width: `${calculateFillPercentage(count)}%`,
              backgroundColor: barColors[index],
              borderRadius: '4px', // Optional: Add border radius for rounded corners
              zIndex: data.length - index, // Use z-index to control the layering
            }}
            data-testid={dataTestId || `${name}-${barColors[index]}-bar`}
          />
        ) : null
      )}
    </div>
  );
};

MultiColoredBar.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  barColors: PropTypes.array.isRequired,
  dataTestId: PropTypes.string,
};

MultiColoredBar.defaultProps = {
  dataTestId: null,
};

export const HorizontalBar = ({
  name,
  count,
  total,
  barColor,
  barColors,
  secondaryText,
  overridePercentage,
  nameOnClick,
  formatMultiColorDataCallback,
  ...otherProps
}) => {
  let fillPercentage = 0;
  if (count !== null && count > 0) {
    fillPercentage = (count / total) * 100;
  }

  return (
    <BarDataContainer>
      {nameOnClick ? (
        <InlineTextButton
          dataTestId={`horizontal-bar-${formatDataTestName(name)}`}
          onClick={nameOnClick}
        >
          {name}
        </InlineTextButton>
      ) : (
        <NameText dataTestId={`horizontal-bar-${formatDataTestName(name)}`}>
          {name}
        </NameText>
      )}

      {secondaryText ? (
        <SecondaryTextContainer>
          <Text
            dataTestId={`horizontal-bar-secondary-${formatDataTestName(name)}`}
          >
            {secondaryText}
          </Text>
        </SecondaryTextContainer>
      ) : null}
      <Wrapper>
        <StackedBarContainer>
          <StackedBarBackground>
            {formatMultiColorDataCallback ? (
              <MultiColoredBar
                name={name}
                total={total}
                data={formatMultiColorDataCallback(otherProps)}
                barColors={barColors}
              />
            ) : (
              <StackedBarValue
                fillpercentage={overridePercentage ?? fillPercentage}
                barColor={barColor}
                data-testid={`horizontal-bar-stacked-bar-${formatDataTestName(
                  name
                )}`}
              />
            )}
          </StackedBarBackground>
        </StackedBarContainer>
        <CountWrapper>
          <Text dataTestId={`horizontal-bar-count-${formatDataTestName(name)}`}>
            {count}
          </Text>
        </CountWrapper>
      </Wrapper>
    </BarDataContainer>
  );
};

HorizontalBar.propTypes = {
  name: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  barColor: PropTypes.string.isRequired,
  barColors: PropTypes.array,
  secondaryText: PropTypes.string,
  overridePercentage: PropTypes.number,
  nameOnClick: PropTypes.func,
  formatMultiColorDataCallback: PropTypes.func,
};

HorizontalBar.defaultProps = {
  barColors: undefined,
  secondaryText: '',
  overridePercentage: undefined,
  nameOnClick: null,
  formatMultiColorDataCallback: null,
};

const HorizontalBarWidget = ({
  graphData,
  graphDataName,
  title,
  subheader,
  total,
  upperBound,
  barColor,
  barColors,
  countKey,
  breakpointWidth,
  btnText,
  btnOnClick,
  btnDataTestId,
  secondaryText,
  overridePercentage,
  nameOnClick,
  formatMultiColorDataCallback,
  bubbleChildren,
  bubbleProps,
}) => {
  const barUpperBound = upperBound || total;

  const [isHovering, setIsHovering] = useState(false);
  return (
    <Widget
      title={title}
      subheader={subheader}
      total={total}
      breakpointWidth={breakpointWidth}
      btnText={btnText}
      btnOnClick={btnOnClick}
      btnDataTestId={btnDataTestId}
    >
      {bubbleChildren ? (
        <Bubble
          isOpen={isHovering}
          onClickOutside={() => setIsHovering(false)}
          moveBubbleRightVal={20}
          moveLeftVal={201}
          bubbleWidth={160}
          top="0px"
          flipYAxis
          zIndex={101}
          dataTestId="UnclaimedMultiGroupWidget-sort-by-bubble"
          {...bubbleProps}
        >
          {bubbleChildren}
        </Bubble>
      ) : null}
      <GraphContainer
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {graphData.map((graphData, i) => {
          return (
            <HorizontalBar
              name={graphData[graphDataName]}
              count={graphData[countKey]}
              key={i}
              barColor={barColors ? barColors[i] : barColor}
              barColors={barColors}
              total={barUpperBound}
              secondaryText={graphData[secondaryText]}
              overridePercentage={graphData[overridePercentage]}
              nameOnClick={graphData[nameOnClick]}
              formatMultiColorDataCallback={formatMultiColorDataCallback}
              {...graphData} // Makes nested data available to use in callback
            />
          );
        })}
      </GraphContainer>
    </Widget>
  );
};

HorizontalBarWidget.propTypes = {
  key: PropTypes.string, // Allows ability to render with interval if needed
  title: PropTypes.string.isRequired,
  subheader: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.node,
  ]),
  barColor: PropTypes.string,
  graphData: PropTypes.array.isRequired,
  graphDataName: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  // UpperBound is used for the GRAPH to determine the max width of the bar
  // When not present, use the total.
  upperBound: PropTypes.number,
  barColors: PropTypes.array,
  countKey: PropTypes.string.isRequired,
  btnText: PropTypes.string,
  btnOnClick: PropTypes.func,
  btnDataTestId: PropTypes.string,
  breakpointWidth: PropTypes.string,
  secondaryText: PropTypes.string,
  overridePercentage: PropTypes.string,
  nameOnClick: PropTypes.string,
  formatMultiColorDataCallback: PropTypes.func,
  bubbleChildren: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.node,
  ]),
  bubbleProps: PropTypes.object,
};

HorizontalBarWidget.defaultProps = {
  key: null,
  subheader: undefined,
  barColor: COLORS.PK.GOLD_100,
  barColors: undefined,
  btnText: undefined,
  btnOnClick: undefined,
  btnDataTestId: undefined,
  breakpointWidth: undefined,
  secondaryText: '',
  overridePercentage: '',
  nameOnClick: '',
  formatMultiColorDataCallback: null,
  upperBound: undefined,
  bubbleChildren: null,
  bubbleProps: {},
};

const areEqual = (prevProps, nextProps) =>
  prevProps.total === nextProps.total && prevProps.key === nextProps.key;

export default React.memo(HorizontalBarWidget, areEqual);
